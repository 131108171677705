<template>
  <section class="goldies-picks-sec sec-nav-blur">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="goldies-picks-sec-container d-flex flex-wrap w-100">
            <div class="gtp-banner" >
              <div class="gtp-banner-content" id="gtp-banner-block">
                <!-- <img :src="`${baseUrl}${topPickImg}`" alt="" class="gtp-banner-img">
                <img src="../../../assets/images/goldies-pick-banner-mobile.svg" alt=""
                  class="gtp-banner-img gtp-banner-img-mobile"> -->
                <div id="gtp-banner-graphic" v-if="products.length <= 3">
                  <h3 class="fs-44 fw-700">{{ topPickTitle }}</h3>
                </div>
                <div id="gtp-banner-graphic-2" v-if="products.length > 3">
                  <h3 class="fs-44 fw-700">{{ topPickTitle }}</h3>
                </div>
                <span id="gtp-animation-trigger"></span>
              </div>
            </div>
            <div class="goldies-top-picks-block" :class="topPicksBlockClasses()">
              <!-- <product-card></product-card>     -->
              <product-card class="gtp-single-1 goldie-product-block" v-if="products[0]" @click="openProdDetailPopup(products[0]._id)"
                :from="typeOfProducts"
                :productName="products[0].productName"
                :uniqueId="products[0].uniqueId"
                :brandName="products[0].brand.name"
                :fullSize="products[0].fullSize"
                :_id="products[0]._id"
                :isBookmark="products[0].isBookmark"
                :notesFromTopPicks="'Fits just right'"
                :productImages="products[0].productImages || []"
              ></product-card>
              <product-card class="gtp-single-2 goldie-product-block" v-if="products[1]" @click="openProdDetailPopup(products[1]._id)"
                :from="typeOfProducts"
                :productName="products[1].productName"
                :uniqueId="products[1].uniqueId"
                :brandName="products[1].brand.name"
                :fullSize="products[1].fullSize"
                :_id="products[1]._id"
                :isBookmark="products[1].isBookmark"
                :notesFromTopPicks="'Fits just right'"
                :productImages="products[1].productImages || []"
              ></product-card>
              <product-card class="gtp-single-3 goldie-product-block" v-if="products[2]" @click="openProdDetailPopup(products[2]._id)"
                :from="typeOfProducts"
                :productName="products[2].productName"
                :uniqueId="products[2].uniqueId"
                :brandName="products[2].brand.name"
                :fullSize="products[2].fullSize"
                :_id="products[2]._id"
                :isBookmark="products[2].isBookmark"
                :notesFromTopPicks="'Fits just right'"
                :productImages="products[2].productImages || []"
              ></product-card>
              <product-card class="gtp-single-1 goldie-product-block" :class="{ 'gtpb4-row-2-1': products.length == 4 }" @click="openProdDetailPopup(products[3]._id)" v-if="products[3]"
                :from="typeOfProducts"
                :productName="products[3].productName"
                :uniqueId="products[3].uniqueId"
                :brandName="products[3].brand.name"
                :fullSize="products[3].fullSize"
                :_id="products[3]._id"
                :isBookmark="products[3].isBookmark"
                :notesFromTopPicks="'Fits just right'"
                :productImages="products[3].productImages || []"
              ></product-card>
              <product-card class="gtp-single-2 goldie-product-block" v-if="products[4]" @click="openProdDetailPopup(products[4]._id)"
                :from="typeOfProducts"
                :productName="products[4].productName"
                :uniqueId="products[4].uniqueId"
                :brandName="products[4].brand.name"
                :fullSize="products[4].fullSize"
                :_id="products[4]._id"
                :isBookmark="products[4].isBookmark"
                :notesFromTopPicks="'Fits just right'"
                :productImages="products[4].productImages || []"
              ></product-card>
              <product-card class="gtp-single-3 goldie-product-block" v-if="products[5]" @click="openProdDetailPopup(products[5]._id)"
                :from="typeOfProducts"
                :productName="products[5].productName"
                :uniqueId="products[5].uniqueId"
                :brandName="products[5].brand.name"
                :fullSize="products[5].fullSize"
                :_id="products[5]._id"
                :isBookmark="products[5].isBookmark"
                :notesFromTopPicks="'Fits just right'"
                :productImages="products[5].productImages || []"
              ></product-card>
              <div class="gtp-subscribe-block" :class="{'gtpb4-row-2-2': products.length == 4}"
                v-if="showRetailerBox()">
                <div class="gtp-subscribe-block-content">
                  <img src="assets/images/goldies-fav-friend-icon.svg" alt="">
                  <h3 class="fs-24 fw-500">Don't see a style on Goldie?</h3>
                  <div class="gtp-submit-retailer-cta">
                    <a href="javascript:void(0);" class="fg-btn-link submit-retailer-link">
                      <span>Submit a Retailer</span>
                      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                          stroke="#1F5CD7" stroke-width="2"></path>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="gtp-subscribe-block gtp-subscribe-block-mobile" v-if="showRetailerBox()">
              <div class="gtp-subscribe-block-content">
                <img src="assets/images/goldies-fav-friend-icon.svg" alt="">
                <h3 class="fs-24 fw-500">Don't see a style on Goldie?</h3>
                <div class="gtp-submit-retailer-cta">
                  <a href="javascript:void(0);" class="fg-btn-link submit-retailer-link">
                    <span>Submit a Retailer</span>
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                        stroke="#1F5CD7" stroke-width="2"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="gtpl-top-picks-cta">
            <a href="/products" class="fg-btn-link">
              <span>See All Results </span>
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.49219 0.411499C8.49219 1.25722 9.22553 2.52277 9.96689 3.58562C10.9215 4.95402 12.0609 6.14943 13.3682 7.06213C14.3476 7.74556 15.5368 8.40162 16.4922 8.40162M8.49219 16.4115C8.49219 15.5658 9.22553 14.3002 9.96689 13.2374C10.9215 11.8691 12.0609 10.6736 13.3682 9.76087C14.3476 9.07744 15.5368 8.42138 16.4922 8.42138M16.4922 8.4115H0.492188"
                  stroke="#1F5CD7" stroke-width="2"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <submit-retailer-popup></submit-retailer-popup>
</template>

<script>
  import $ from "jquery";
  import axios from "axios";
  import SubmitRetailerPopup from "../submit-retailers/SubmitRetailerPopup.vue";
  import ProductCard from "../../ui/ProductCard.vue";
  /* import { gsap, ScrollTrigger } from "gsap/all"; */
  import ScrollMagic from "scrollmagic";
  import lottie from 'lottie-web';

  export default {
    components: {
      SubmitRetailerPopup,
      ProductCard
    },
    props: ["topPickTitle", "topPickImg", "topPicksProductsArr"],
    data() {
      return {
        typeOfProducts: "toppicks",
        activeWishlist: true,
        products: this.topPicksProductsArr || [],
        baseUrl: process.env.VUE_APP_BASE_URL
      }
    },
    async mounted() {
      /* gsap.registerPlugin(ScrollTrigger); */
      // JQuery stuff
      /*Submit Retailer form toggle*/
      $('.submit-retailer-link').on('click',function(){
        $('.popup-submit-retailer').css({'display':'flex'});
        $('body').addClass('noscroll');
      })
      $('.popup-signup-close').on('click',function(){
        $('.popup-signup-login').hide();
        $('.popup-measurement').hide();
        $('.popup-submit-retailer').hide();
        $('.popup-feedback').hide();
        $('body').removeClass('noscroll');
      })
      if($(window).width() < 767){
        $('.goldies-top-picks-block').slick({
					infinite: false,
					adaptiveHeight: true,
					arrows: false, // Disable arrows
					cssEase: "ease-in-out",
					slidesToShow: 1,
					dots: false,
				});
      }
      // Call api for products
      // let measurement = this.$store.getters['products/getMeasurementFromLocal'];
      // if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
      //   let getToken = this.$store.getters.getToken;
      //   let headers = {};
      //   if (getToken) {
      //     headers.Authorization = "Bearer " + getToken;
      //   }
      //   const topPicks = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/top-picks`, {
      //     waistInput: parseFloat(measurement.waist),
      //     hipInput: parseFloat(measurement.hip),
      //     thighInput: parseFloat(measurement.thigh),
      //     inseamInput: parseFloat(measurement.inseam)
      //   }, {
      //     headers
      //   });

      //   if (topPicks && topPicks.status == 200 && topPicks.data.code == 200) {
      //     this.products = topPicks.data.data.products;
      //   }
      // }
      /*Goldie top picks animation scrollmagic*/
      // Load Lottie animation
      var animation = lottie.loadAnimation({
          container: document.getElementById('gtp-banner-graphic'), // The DOM element to render the animation in
          renderer: 'svg',
          loop: false,
          autoplay: false,
          path: 'animations/gtp.json' // The path to the animation JSON file
      });

      // Initialize ScrollMagic Controller
      var controller = new ScrollMagic.Controller();

      // Create a ScrollMagic Scene
      new ScrollMagic.Scene({
          triggerElement: '#gtp-banner-graphic', // The element that triggers the animation
          duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
          triggerHook: 0.6 // Start animation when trigger is 75% down the viewport
      })
      .addTo(controller)
      .on('enter', function () {
        //about_animation.play(); //Play animation only once
        animation.goToAndStop(0, true);
        animation.play();
      })
      .on('leave', function () {
          // Hold the end state of the animation when leaving the scene
          animation.goToAndStop(animation.totalFrames - 1, true);
      });
      /*Goldie top picks animation scrollmagic*/


      /*Goldie top picks 2 rows animation scrollmagic*/
      /*Home count 2 animation scrollmagic*/
      // Load Lottie animation
      var gtp_animation_2 = lottie.loadAnimation({
        container: document.getElementById('gtp-banner-graphic-2'), // The DOM element to render the animation in
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: 'animations/gtp2.json' // The path to the animation JSON file
    });

    // Initialize ScrollMagic Controller
    var controller_gtp_animation_2 = new ScrollMagic.Controller();

    // Create a ScrollMagic Scene
    new ScrollMagic.Scene({
        triggerElement: '#gtp-banner-graphic-2', // The element that triggers the animation
        duration: window.innerHeight * 0.7, // Adjust duration to 70% of viewport height
        triggerHook: 0.4 // Start animation when trigger is 75% down the viewport
    })
    .addTo(controller_gtp_animation_2)
    .on('enter', function () {
        //gtp_animation_2.play();
        gtp_animation_2.goToAndStop(0, true);
        gtp_animation_2.play();
    })
    .on('leave', function () {
        // Hold the end state of the animation when leaving the scene
        gtp_animation_2.goToAndStop(gtp_animation_2.totalFrames - 1, true);
    });
  /*Home count 2 animation scrollmagic*/
      /*Goldie top picks 2 rows animation scrollmagic*/

    },
    methods: {
      openProdDetailPopup: async function (productId) {
        // Call api for product detail
        let measurement = this.$store.getters['products/getMeasurementFromLocal'];
        if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
          let getToken = this.$store.getters.getToken;
          let headers = {};
          if (getToken) {
            headers.Authorization = "Bearer " + getToken;
          }
          axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/detail`, {
            productId: productId,
            waist: measurement.waist,
            hip: measurement.hip,
            thigh: measurement.thigh,
            inseam: measurement.inseam
          }, {
            headers
          })
            .then((product) => {
              $(document).on('click', '.goldie-product-block a:not(.gpb-wishlist)', function () {
                // change URL
                // window.history.pushState({}, '', `${process.env.VUE_APP_BASE_URL}/product/detail/${product.data.data.detail.slug}`);
                const currentHostname = window.location.hostname;
                const newPath = `/product/detail/${product.data.data.detail.slug}`;
                const newUrl = `https://${currentHostname}${newPath}`;
                window.history.pushState({}, '', newUrl);
              })
              this.emitter.emit("product-detail-data", {
                product: product.data.data.detail,
                fitNotes: product.data.data.fitNotes
              });
            })
        }
      },
      topPicksBlockClasses: function () {
        if (this.topPicksProductsArr && this.topPicksProductsArr.length && this.topPicksProductsArr.length > 3) {
          if (this.topPicksProductsArr.length == 4) {
            return ["goldies-top-picks-block-1", "goldies-top-picks-block-4"];
          }
          if (this.topPicksProductsArr.length == 5) {
            return ["goldies-top-picks-block-2", "goldies-top-picks-block-5"];
          }
          if (this.topPicksProductsArr.length == 6) {
            return ["goldies-top-picks-block-3", "goldies-top-picks-block-6"];
          }
        } else {
          return [`goldies-top-picks-block-${this.topPicksProductsArr.length}`];
        }
      },
      showRetailerBox: function () {
        let topPicksProducts = this.topPicksProductsArr;
        if (topPicksProducts && topPicksProducts.length) {
          if (topPicksProducts.length > 3) {
            if (topPicksProducts[5] === undefined) {
              return true;
            }
          } else {
            if (topPicksProducts[2] === undefined) {
              return true;
            }
          }
        } else {
          return false;
        }
      }
    }
  }
</script>

<style></style>
