<template>
  <div class="results-other-matches-block">
    <a class="btn btn-filter" href="javascript:void(0);" title="Filter">Filter</a>
    <div class="rom-title-block d-flex flex-wrap w-100">
      <div class="rom-title d-flex flex-wrap w-100">
        <h3 class="fs-40 fw-400">{{ fitMatchesText }}</h3>
        <div class="rom-count fs-16">Showing 1 - {{ showingProducts }} out of {{ totalProducts }} products</div>
      </div>
      <div class="rom-sortby-block">
        <a href="javascript:void(0);" class="sortby-btn fg-btn-primary" @click="toggleDiv">
          <svg class="sort-icon-1" width="32" height="33" viewBox="0 0 32 33" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8 11.249C8.552 11.249 9 10.802 9 10.249V9.24902C9 8.69602 8.552 8.24902 8 8.24902C7.448 8.24902 7 8.69602 7 9.24902V10.249C7 10.802 7.448 11.249 8 11.249Z"
              fill="#0F2C66" />
            <path
              d="M10.914 22.249H9.00001V13.249C9.00001 12.696 8.55201 12.249 8.00001 12.249C7.44801 12.249 7.00001 12.696 7.00001 13.249V22.249H5.08601C4.68501 22.249 4.48401 22.734 4.76801 23.017L7.68201 25.931C7.85801 26.107 8.14301 26.107 8.31801 25.931L11.232 23.017C11.515 22.734 11.315 22.249 10.914 22.249Z"
              fill="#0F2C66" />
            <path
              d="M14 10.249H20C20.553 10.249 21 9.80202 21 9.24902C21 8.69602 20.553 8.24902 20 8.24902H14C13.448 8.24902 13 8.69602 13 9.24902C13 9.80202 13.448 10.249 14 10.249Z"
              fill="#0F2C66" />
            <path
              d="M14 15.249H22C22.553 15.249 23 14.802 23 14.249C23 13.696 22.553 13.249 22 13.249H14C13.448 13.249 13 13.696 13 14.249C13 14.802 13.448 15.249 14 15.249Z"
              fill="#0F2C66" />
            <path
              d="M14 20.249H24C24.553 20.249 25 19.802 25 19.249C25 18.696 24.553 18.249 24 18.249H14C13.448 18.249 13 18.696 13 19.249C13 19.802 13.448 20.249 14 20.249Z"
              fill="#0F2C66" />
            <path
              d="M26 23.249H14C13.448 23.249 13 23.696 13 24.249C13 24.802 13.448 25.249 14 25.249H26C26.553 25.249 27 24.802 27 24.249C27 23.696 26.553 23.249 26 23.249Z"
              fill="#0F2C66" />
          </svg>
          <span>Sort By</span>
          <svg class="sort-icon-2" width="24" height="25" viewBox="0 0 24 25" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9.24902L12 15.249L18 9.24902" stroke="#0F2C66" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </a>
      </div>
      <!-- Sortby Dropdown-->
      <div class="sortby-dropdown-block" v-if="showDiv">
        <ul>
          <!-- <li><a href="javascript:void(0);" @click="sortOptions('price-low-to-high')">Price: Low to High</a></li>
          <li><a href="javascript:void(0);" @click="sortOptions('price-high-to-low')">Price: High to Low</a></li> -->
          <li><a :class="{ 'active': sortDropdownState === 'price-low-to-high' }" href="javascript:void(0);"
              @click="sortOptions('price-low-to-high')">Price: Low to
              High</a></li>
          <li><a :class="{ 'active': sortDropdownState === 'price-high-to-low' }" href="javascript:void(0);"
              @click="sortOptions('price-high-to-low')">Price: High to Low</a></li>
          <li><a :class="{ 'active': sortDropdownState === 'stretch-low-to-high' }" href="javascript:void(0);"
              @click="sortOptions('stretch-low-to-high')">Stretch: Low to High</a></li>
          <li><a :class="{ 'active': sortDropdownState === 'stretch-high-to-low' }" href="javascript:void(0);"
              @click="sortOptions('stretch-high-to-low')">Stretch: High to
              Low</a></li>
          <li><a href="javascript:void(0);" :class="{ 'active': (sortDropdownState != 'price-low-to-high' && sortDropdownState != 'price-high-to-low' && sortDropdownState != 'stretch-low-to-high' && sortDropdownState != 'stretch-high-to-low') }"
              @click="sortOptions('clear')">Best Fit</a></li>
        </ul>
      </div>
      <!-- Sortby Dropdown-->
    </div>
    <div class="rom-list-block d-flex flex-wrap w-100">
      <product-card v-for="product in displayedProducts"
        :key="product._id"
        :price="product.priceRange"
        :productName="product.productName"
        :uniqueId="product.uniqueId"
        :brandName="product.brand.name"
        :fullSize="product.fullSize"
        :from="product.topPick"
        :_id="product._id"
        :isBookmark="product.isBookmark"
        :productImages="product.productImages || []" :size="product.size"
        @click="openProdDetailPopup(product._id)"
      ></product-card>
      <div v-if="isLoading" class="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
    <the-trigger @triggerIntersected="loadMore" v-if="showTrigger"></the-trigger>
  </div>
</template>

<script>
  import $ from "jquery";
  import axios from "axios";

  import ProductCard from "../../ui/ProductCard.vue";
  import TheTrigger from "./TheTrigger.vue";

  export default {
    components: {
      ProductCard,
      TheTrigger
    },
    data () {
      return {
        products: [],
        showTrigger: true,
        displayedProducts: [],
        page: 0,
        perProduct: 9,
        loader: false,
        typeOfProducts: "otherpicks",
        totalProducts: 0,
        showingProducts: 9,
        showDiv:false,
        filter: {
          brands: [],
          styles: [],
          prices: [],
          rises: []
        },
        sorting: {
          priceLH: 0,
          priceHL: 0,
          stretchLH: 0,
          stretchHL: 0
        },
        isLoading: false,
        filterApplied: false,
        sortDropdownState: null,
        fitMatchesText: "Other Fit Matches"
      }
    },
    methods: {
      openProdDetailPopup: async function (productId) {
        // Call api for product detail
        let measurement = this.$store.getters['products/getMeasurementFromLocal'];
        if (measurement && measurement.waist && measurement.hip && measurement.thigh && measurement.inseam) {
          let getToken = this.$store.getters.getToken;
          let headers = {};
          if (getToken) {
            headers.Authorization = "Bearer " + getToken;
          }
          axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/detail`, {
            productId: productId,
            waist: measurement.waist,
            hip: measurement.hip,
            thigh: measurement.thigh,
            inseam: measurement.inseam
          }, {
            headers
          })
            .then((product) => {
              // change URL
              $(document).on('click', '.goldie-product-block a:not(.gpb-wishlist)', function(){
                // window.history.pushState({}, '', `${process.env.VUE_APP_BASE_URL}/product/detail/${product.data.data.detail.slug}`);
                const currentHostname = window.location.hostname;
                const newPath = `/product/detail/${product.data.data.detail.slug}`;
                const newUrl = `https://${currentHostname}${newPath}`;
                window.history.pushState({}, '', newUrl);
              });
              this.emitter.emit("product-detail-data", {
                product: product.data.data.detail,
                fitNotes: product.data.data.fitNotes
              });
            })
        }
      },
      sortOptions: async function (data) {
        this.showDiv = false;
        this.page = 0;
        this.displayedProducts = [];
        $(window).scrollTop(0);
        if (data == "price-low-to-high") {
          this.sorting.priceLH = 1;
          this.sortDropdownState = data;
          this.emitter.emit("product-sort-data", {
            sortApplied: true
          });
          this.fitMatchesText = "Your Fit Matches";
          if (this.filter.brands.length || this.filter.styles.length || this.filter.prices.length || this.filter.rises.length) {
            this.emitter.emit("top-pick-hide", {
              filterApplied: true,
              sortApplied: true,
              hide: true
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              filterApplied: false,
              sortApplied: true,
              hide: true
            });
          }
        } else if (data == "price-high-to-low") {
          this.sorting.priceHL = 1;
          this.sortDropdownState = data;
          this.emitter.emit("product-sort-data", {
            sortApplied: true
          });
          this.fitMatchesText = "Your Fit Matches";
          if (this.filter.brands.length || this.filter.styles.length || this.filter.prices.length || this.filter.rises.length) {
            this.emitter.emit("top-pick-hide", {
              filterApplied: true,
              sortApplied: true,
              hide: true
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              filterApplied: false,
              sortApplied: true,
              hide: true
            });
          }
        } else if (data == "stretch-low-to-high") {
          this.sorting.stretchLH = 1;
          this.sortDropdownState = data;
          this.emitter.emit("product-sort-data", {
            sortApplied: true
          });
          this.fitMatchesText = "Your Fit Matches";
          if (this.filter.brands.length || this.filter.styles.length || this.filter.prices.length || this.filter.rises.length) {
            this.emitter.emit("top-pick-hide", {
              filterApplied: true,
              sortApplied: true,
              hide: true
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              filterApplied: false,
              sortApplied: true,
              hide: true
            });
          }
        } else if (data == "stretch-high-to-low") {
          this.sorting.stretchHL = 1;
          this.sortDropdownState = data;
          this.emitter.emit("product-sort-data", {
            sortApplied: true
          });
          this.fitMatchesText = "Your Fit Matches";
          if (this.filter.brands.length || this.filter.styles.length || this.filter.prices.length || this.filter.rises.length) {
            this.emitter.emit("top-pick-hide", {
              filterApplied: true,
              sortApplied: true,
              hide: true
            });
          } else {
            this.emitter.emit("top-pick-hide", {
              filterApplied: false,
              sortApplied: true,
              hide: true
            });
          }
        } else {
          this.sorting.priceLH = 0;
          this.sorting.priceHL = 0;
          this.sorting.stretchLH = 0;
          this.sorting.stretchHL = 0;
          this.sortDropdownState = true;
          this.emitter.emit("product-sort-data", {
            sortApplied: false
          });
          if (this.filter.brands.length || this.filter.styles.length || this.filter.prices.length || this.filter.rises.length) {
            this.fitMatchesText = "Your Fit Matches";
            this.emitter.emit("top-pick-hide", {
              filterApplied: true,
              sortApplied: false,
              hide: false
            });
          } else {
            this.fitMatchesText = "Other Fit Matches";
            this.emitter.emit("top-pick-hide", {
              filterApplied: false,
              sortApplied: false,
              hide: false
            });
          }
        }
        setTimeout(async () => {
          this.isLoading = false;
          await this.productLoadOnMount();
        }, 500);
        return;
      },
      async loadMore () {
        if (this.isLoading) return;
        this.isLoading = true;

        this.page += 1;
        const endIndex = this.page * this.perProduct;
        const startIndex = (this.page - 1) * this.perProduct;

        if (startIndex >= this.totalProducts) {
          this.isLoading = false;
          return;
        }
        setTimeout(() => {
          const newProducts = this.products.slice(startIndex, endIndex);
          this.displayedProducts = [...this.displayedProducts, ...newProducts];
          this.showingProducts = this.displayedProducts.length;
          this.isLoading = false;
        }, 1500);
      },
      async productLoadOnMount () {
        if (this.isLoading) return;
        this.isLoading = true;

        const measurement = this.$store.getters['products/getMeasurementFromLocal'];
        if (!measurement || !measurement.waist || !measurement.hip || !measurement.thigh || !measurement.inseam) {
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Please login for measurement or update the measurement.",
            showConfirmButton: false,
            timer: 1900,
          });
          setTimeout(() => {
            this.$router.push("/");
          }, 1700);
          this.isLoading = false;
          return;
        }

        this.products = [];
        this.displayedProducts = [];
        this.page = 0;
        this.showingProducts = 0;

        // Call api for products
        const getToken = this.$store.getters.getToken;
        const headers = getToken ? { Authorization: "Bearer " + getToken } : {};
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/product/plus/top/picks?brands=${JSON.stringify(this.filter.brands)}&styles=${JSON.stringify(this.filter.styles)}&prices=${JSON.stringify(this.filter.prices)}&rises=${JSON.stringify(this.filter.rises)}&priceLH=${this.sorting.priceLH}&priceHL=${this.sorting.priceHL}&stretchLH=${this.sorting.stretchLH}&stretchHL=${this.sorting.stretchHL}`, {
          waistInput: parseFloat(measurement.waist),
          hipInput: parseFloat(measurement.hip),
          thighInput: parseFloat(measurement.thigh),
          inseamInput: parseFloat(measurement.inseam)
        }, {
          headers
        });

        if (response && response.status == 200 && response.data.code == 200) {
          this.products = response.data.data.products;
          this.totalProducts = this.products.length;
          this.page = 1;
          let initialProducts = this.products.slice(0, this.perProduct);
          setTimeout(() => {
            this.emitter.emit("product-filter-disable", {}); // to enable the filters
            this.displayedProducts = initialProducts;
            this.showingProducts = initialProducts.length;
            this.isLoading = false;

            console.log(this.filter.brands.length || this.filter.styles.length || this.filter.prices.length || this.filter.rises.length);

            if (this.filter.brands.length || this.filter.styles.length || this.filter.prices.length || this.filter.rises.length) {
              this.emitter.emit("product-show-box", {
                displayedProducts: this.displayedProducts || []
              });
            }
          }, 1500);
        } else {
          this.$swal({
            position: "top",
            icon: false,
            title: false,
            html: "Something went wrong.",
            showConfirmButton: false,
            timer: 1900,
          });
          setTimeout(() => {
            window.location.href = "/";
          }, 1700);
          return;
        }
      },
      toggleDiv() {
        this.showDiv = !this.showDiv;
      }
    },
    async mounted() {
      // Jquery stuff
      $('.measurements-form-overlay').on('click',function(){
        $(this).fadeOut();
        $('body').removeClass('noscroll');
        $('.home-banner-form-block-content.scrolled .home-banner-form-toggle-wrapper').fadeOut();
        $('.form-toggle-arrow').toggleClass('rotate');
        $('.mb-os-form').fadeOut(100);
        $('.product-modal-content').removeClass('open');
        $('.fg-navigation').removeClass('reduceindex');
      })
      $('.how-to-measure').on('click',function(){
        $('.popup-measurement').fadeIn();
        $('body').addClass('noscroll');
      })
      $(document).on('click', '.goldie-product-block a:not(.gpb-wishlist)', function(){
        $('.product-modal-content').addClass('open');
        $('.fg-navigation').addClass('reduceindex');
        //$('.measurements-form-overlay').show();
        $('body').addClass('noscroll');
      });
      // $(document).on('click', '.pdc-icon', function(){

      //   $('.product-modal-content').removeClass('open');
      //   $('.fg-navigation').removeClass('reduceindex');
      //   $('.measurements-form-overlay').hide();
      //   $('body').removeClass('noscroll');
      // })
      /*Product details block hover*/
      // $(document).on("mouseenter", ".goldie-product-block", function() {
      //   $(this).children('.gpb-content').children('.gpb-content-link').children('.gpb-hover-block-wrapper').fadeIn(10);
      // });
      // $(document).on("mouseleave", ".goldie-product-block", function() {
      //   $(this).children('.gpb-content').children('.gpb-content-link').children('.gpb-hover-block-wrapper').fadeOut(10);
      // });
      /*Product details block hover*/
      // Jquery stuff

      // call api
      await this.productLoadOnMount();

      // emit from filters
      this.emitter.on("product-filter-brand-data", async (data) => {
        // this.showTrigger = false;
        window.scrollTo(0, 0);
        this.page = 0;
        this.products = [];
        this.displayedProducts = [];
        this.showingProducts = 0;
        if (data.brands) this.filter.brands = data.brands;
        if (data.styles) this.filter.styles = data.styles;
        if (data.prices) this.filter.prices = data.prices;
        if (data.rises) this.filter.rises = data.rises;
        if (this.filter.brands.length || this.filter.styles.length || this.filter.prices.length || this.filter.rises.length) {
          this.fitMatchesText = "Your Fit Matches";
        }
        await this.productLoadOnMount();
      });
    }
  }
</script>

<style>
  .loading-indicator {
    text-align: center;
    margin-top: 10px;
  }
</style>
